import React from 'react';
import { timestampToDate } from '../../utils';
import BootstrapTable from 'react-bootstrap-table-next';

function renderCustomer(id, customers) {
    const customer = customers.find(customer => customer.id === id);
    return customer.name;
}

function cleanModels(models, customers) {
  return models.map(model => {
    return {
      id: model.id,
      internal_id: model.internal_id,
      customer: renderCustomer(model.customer_id, customers),
      received_at: timestampToDate(model.received_at),
      job_status: model.job_status,
      invoice_status: model.invoice_status,
      payment_method: model.payment_method,
      notes: model.notes,
      is_published: model.is_published
    }
  });
}

const columns = [{
  dataField: 'internal_id',
  text: 'ID',
  sort: true,
  sortFunc: (a, b, order, dataField, rowA, rowB) => {
    a = a.replace(/\D/g,'');
    b = b.replace(/\D/g,'');
    if (order === 'asc') {
      return a - b;
    }
    return b - a;
  },
  classes: 'table-cell'
}, {
  dataField: 'customer',
  text: 'Customer',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'received_at',
  text: 'Received At',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'job_status',
  text: 'Job Status',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'invoice_status',
  text: 'Invoice Status',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'payment_method',
  text: 'Payment Method',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'notes',
  text: 'Notes',
  sort: false
}, {
  dataField: 'is_published',
  text: 'Is Published',
  formatter: (cell) => cell ? '✓' : 'x',
  sort: true
}];

const rowClass = (row, rowIndex) => {
  if (row.job_status === "COMPLETE" && row.invoice_status == "GENERATED") {
    return 'light-blue';
  }
  if (row.job_status === "COMPLETE" && row.invoice_status == "INVOICED") {
    return 'light-orange';
  }
  if (row.job_status === "COMPLETE" && row.invoice_status == "PAID") {
    return 'light-grey';
  }
  return '';
};

const WorkOrdersTable = (props) => {
  const work_orders = cleanModels(props.state.models[props.state.tab], props.state.models['customers']);
  console.log("work_orders: ", work_orders);

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (!props.state.is_loading) {
        props.setSelectedModel(e, props.state.tab, rowIndex, row.id);
      }
    }
  };
  
  return (
    <BootstrapTable keyField='internal_id' data={ work_orders } columns={ columns } rowEvents={ rowEvents } rowClasses={ rowClass } bootstrap4 />
  )
}

export default WorkOrdersTable;