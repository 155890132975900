import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { upperCaseWords } from '../../utils';

function cleanModel(model) {
  return {
    id: model.id,
    internal_id: model.internal_id,
    name: model.name,
    standard_test_templates: model.standard_test_templates || [],
    add_on_test_templates: model.add_on_test_templates || [],
    published: model.published ? true : false,
    is_update: model.is_update
  }
}

const TestTemplateDropdown = ({ label, templates, selectedIds, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown show={isOpen} onToggle={(isOpen) => setIsOpen(isOpen)} className="mb-3">
      <Dropdown.Toggle variant="outline-secondary" className="w-100 text-start">
        {`${label} (${selectedIds.length} selected)`}
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100">
        <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
          {templates.map(template => (
            <Dropdown.Item 
              key={template.id} 
              as="label" 
              className="d-flex align-items-center gap-2"
              onClick={(e) => e.stopPropagation()}
            >
              <Form.Check
                type="checkbox"
                checked={selectedIds.includes(template.id)}
                onChange={(e) => {
                  const newIds = e.target.checked 
                    ? [...selectedIds, template.id]
                    : selectedIds.filter(id => id !== template.id);
                  onChange(newIds);
                }}
              />
              {template.internal_id}
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const PanelsForm = (props) => {
  const selected_model = cleanModel(props.state.selected_model);
  const test_templates = props.state.models['test_templates'] || [];

  return (
    <Modal show={selected_model !== null} onHide={props.resetSelectedModel}>
      <Modal.Header closeButton>
        <Modal.Title>{selected_model.is_update ? 'Edit' : 'Add'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Internal ID</Form.Label>
              <Form.Control 
                name="internal_id" 
                value={selected_model.internal_id} 
                onChange={e => props.handleChange(e)} 
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control 
                name="name" 
                value={selected_model.name} 
                onChange={e => props.handleChange(e)} 
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <TestTemplateDropdown
              label="Standard Test Templates"
              templates={test_templates}
              selectedIds={selected_model.standard_test_templates}
              onChange={(newIds) => props.handleChange({
                target: { name: 'standard_test_templates', value: newIds }
              })}
            />
          </Col>
          <Col xs={12}>
            <TestTemplateDropdown
              label="Add-on Test Templates"
              templates={test_templates}
              selectedIds={selected_model.add_on_test_templates}
              onChange={(newIds) => props.handleChange({
                target: { name: 'add_on_test_templates', value: newIds }
              })}
            />
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Published</Form.Label>
              <Form.Select 
                name="published" 
                value={selected_model.published} 
                onChange={e => props.handleChange(e)}
              >
                <option value={true}>true</option>
                <option value={false}>false</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {selected_model.is_update && (
          <Button variant="danger" disabled={props.state.is_loading} onClick={props.deleteModel}>
            Delete
          </Button>
        )}
        <Button 
          variant="primary" 
          disabled={props.state.is_loading} 
          onClick={selected_model.is_update ? props.updateModel : props.createModel}
        >
          {selected_model.is_update ? 'Save' : 'Create'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PanelsForm;