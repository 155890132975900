import { timestampToDate } from './utils';

/******************************************************************
**************************** CONSTANTS ****************************
******************************************************************/

const API = 'https://api.corascience.com'
// const API = 'http://localhost:5000'

/******************************************************************
***************************** HELPERS *****************************
******************************************************************/

async function wrapFetch(endpoint, method, body=undefined, is_json=true) {
  const requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json', 'challenge-token': process.env.REACT_APP_CHALLENGE_TOKEN },
    ...(body !== undefined && { body: body })
  }
  if (!is_json) { delete requestOptions.headers['Content-Type'] }
  return await fetch(`${API}/${endpoint}`, requestOptions);
}

/******************************************************************
******************************* API *******************************
******************************************************************/


/************************************
************* ADDRESSES *************
************************************/

async function createAddress(address) {
  const body = JSON.stringify({
    address_1: address.address_1,
    address_2: address.address_2,
    city: address.city,
    state: address.state,
    postal_code: address.postal_code,
    country: address.country
  });
  const response = await wrapFetch('addresses', 'POST', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function fetchAddresses() {
  return await (await wrapFetch('addresses', 'GET')).json();
}

async function fetchAddress(address) {
  return await (await wrapFetch(`addresses/${address.id}`, 'GET')).json();
}

async function updateAddress(address) {
  const body = JSON.stringify({
    address_1: address.address_1,
    address_2: address.address_2,
    city: address.city,
    state: address.state,
    postal_code: address.postal_code,
    country: address.country
  });
  const response = await wrapFetch(`addresses/${address.id}`, 'PUT', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function deleteAddress(address) {
  const response = await wrapFetch(`addresses/${address.id}`, 'DELETE', {});
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}


/************************************
************* CONTACTS **************
************************************/

async function createContact(contact) {
  const body = JSON.stringify({
    first_name: contact.first_name,
    last_name: contact.last_name,
    title: contact.title,
    email_primary: contact.email_primary,
    email_secondary: contact.email_secondary,
    phone: contact.phone,
    fax: contact.fax,
    role: contact.role,
    customer_id: contact.customer_id
  });
  const response = await wrapFetch('contacts', 'POST', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function fetchContacts() {
  return await (await wrapFetch('contacts', 'GET')).json();
}

async function fetchContact(contact) {
  return await (await wrapFetch(`contacts/${contact.id}`, 'GET')).json();
}

async function updateContact(contact) {
  const body = JSON.stringify({
    first_name: contact.first_name,
    last_name: contact.last_name,
    title: contact.title,
    email_primary: contact.email_primary,
    email_secondary: contact.email_secondary,
    phone: contact.phone,
    fax: contact.fax,
    role: contact.role,
    customer_id: contact.customer_id
  });
  const response = await wrapFetch(`contacts/${contact.id}`, 'PUT', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function deleteContact(contact) {
  const response = await wrapFetch(`contacts/${contact.id}`, 'DELETE', {});
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}


/************************************
************* ACCOUNTS **************
************************************/

async function createBillingAccount(billing_account) {
  const body = JSON.stringify({
    address_id: billing_account.address_id.length === 0 ? -1 : billing_account.address_id,
    contact_id: billing_account.contact_id.length === 0 ? -1 : billing_account.contact_id,
    name: billing_account.name,
    payment_method: billing_account.payment_method
  });
  const response = await wrapFetch('accounts', 'POST', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function fetchBillingAccounts() {
  return await (await wrapFetch('accounts', 'GET')).json();
}

async function fetchBillingAccount(billing_account) {
  return await (await wrapFetch(`accounts/${billing_account.id}`, 'GET')).json();
}

async function updateBillingAccount(billing_account) {
  const body = JSON.stringify({
    address_id: billing_account.address_id,
    contact_id: billing_account.contact_id,
    name: billing_account.name,
    payment_method: billing_account.payment_method
  });
  const response = await wrapFetch(`accounts/${billing_account.id}`, 'PUT', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function deleteBillingAccount(billing_account) {
  const response = await wrapFetch(`accounts/${billing_account.id}`, 'DELETE', {});
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}


/************************************
************* CUSTOMERS *************
************************************/

async function createCustomer(customer) {
  const body = JSON.stringify({
    account_id: customer.account_id.length === 0 ? -1 : customer.account_id,
    address_id: customer.address_id.length === 0 ? -1 : customer.address_id,
    contact_id: customer.contact_id.length === 0 ? -1 : customer.contact_id,
    name: customer.name,
    attention: customer.attention,
    active: customer.active
  });
  const response = await wrapFetch('customers', 'POST', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function fetchCustomers() {
  return await (await wrapFetch('customers', 'GET')).json();
}

async function fetchCustomer(customer) {
  return await (await wrapFetch(`customers/${customer.id}`, 'GET')).json();
}

async function updateCustomer(customer) {
  const body = JSON.stringify({
    account_id: customer.account_id,
    address_id: customer.address_id,
    contact_id: customer.contact_id,
    name: customer.name,
    attention: customer.attention,
    active: customer.active
  });
  const response = await wrapFetch(`customers/${customer.id}`, 'PUT', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function deleteCustomer(customer) {
  const response = await wrapFetch(`customers/${customer.id}`, 'DELETE', {});
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}


/**************************************
************* WORK ORDERS *************
**************************************/


async function createWorkOrder(work_order) {
  const body = JSON.stringify({
    customer_id: work_order.customer_id.length === 0 ? -1 : work_order.customer_id,
    internal_id: work_order.internal_id.length === 0 ? '-1' : work_order.internal_id,
    received_at: timestampToDate(work_order.received_at),
    job_status: work_order.job_status,
    invoice_status: work_order.invoice_status,
    payment_method: work_order.payment_method,
    notes: work_order.notes
  });
  const response = await wrapFetch('work_orders', 'POST', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function fetchWorkOrders() {
  let work_orders = await (await wrapFetch('work_orders', 'GET')).json();
  work_orders = work_orders.sort((a, b) => b.internal_id.replace(/\D/g,'') - a.internal_id.replace(/\D/g,''));
  return work_orders;
}

async function fetchWorkOrdersByUuid(uuid) {
  const response = await wrapFetch(`work_orders/by_uuid/${uuid}`, 'GET');
  if (response.status === 200) {
    let work_orders = await response.json();
    work_orders = work_orders.sort((a, b) => b.internal_id.replace(/\D/g,'') - a.internal_id.replace(/\D/g,''));
    return work_orders;
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function fetchWorkOrder(work_order) {
  return await (await wrapFetch(`work_orders/${work_order.id}`, 'GET')).json();
}

async function fetchLatestWorkOrder() {
  return await (await wrapFetch(`work_orders/latest`, 'GET')).json();
}

async function updateWorkOrder(work_order) {
  const body = JSON.stringify({
    customer_id: work_order.customer_id.length === 0 ? -1 : work_order.customer_id,
    internal_id: work_order.internal_id.length === 0 ? '-1' : work_order.internal_id,
    received_at: timestampToDate(work_order.received_at),
    job_status: work_order.job_status,
    invoice_status: work_order.invoice_status,
    payment_method: work_order.payment_method,
    is_published: work_order.is_published,
    notes: work_order.notes
  });
  const response = await wrapFetch(`work_orders/${work_order.id}`, 'PUT', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function deleteWorkOrder(work_order) {
  const response = await wrapFetch(`work_orders/${work_order.id}`, 'DELETE', {});
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}


/**************************************
*************** SAMPLES ***************
**************************************/


async function createSample(sample) {
  const body = JSON.stringify({
    work_order_id: sample.work_order_id.length === 0 ? -1 : sample.work_order_id,
    internal_id: sample.internal_id.length === 0 ? '-1' : sample.internal_id,
    metadata: sample.metadata
  });
  const response = await wrapFetch('samples', 'POST', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function fetchSamples() {
  let samples = await (await wrapFetch('samples', 'GET')).json();
  samples = samples.sort((a, b) => b.internal_id.replace(/\D/g,'') - a.internal_id.replace(/\D/g,''));
  return samples;
}

async function fetchSamplesByUuid(uuid) {
  const response = await wrapFetch(`samples/by_uuid/${uuid}`, 'GET');
  if (response.status === 200) {
    let samples = await response.json();
    samples = samples.sort((a, b) => b.internal_id.replace(/\D/g,'') - a.internal_id.replace(/\D/g,''));
    return samples;
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function fetchSample(sample) {
  return await (await wrapFetch(`samples/${sample.id}`, 'GET')).json();
}

async function fetchLatestSample() {
  return await (await wrapFetch(`samples/latest`, 'GET')).json();
}

async function updateSample(sample) {
  const body = JSON.stringify({
    work_order_id: sample.work_order_id.length === 0 ? -1 : sample.work_order_id,
    internal_id: sample.internal_id.length === 0 ? '-1' : sample.internal_id,
    metadata: sample.metadata
  });
  const response = await wrapFetch(`samples/${sample.id}`, 'PUT', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function deleteSample(sample) {
  const response = await wrapFetch(`samples/${sample.id}`, 'DELETE', {});
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}


/**************************************
**************** IMAGES ***************
**************************************/


async function createImage(image) {
  const body = JSON.stringify({
    sample_id: image.sample_id.length === 0 ? -1 : image.sample_id,
    base_64: image.base_64
  });
  const response = await wrapFetch('images', 'POST', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function fetchImages() {
  return await (await wrapFetch('images', 'GET')).json();
}

async function fetchImagesForSample(sample) {
  return await (await wrapFetch(`samples/${sample.id}/images`, 'GET')).json();
}

async function updateImage(image) {
  const body = JSON.stringify({
    sample_id: image.sample_id.length === 0 ? -1 : image.sample_id,
    base_64: image.base_64
  });
  const response = await wrapFetch(`images/${image.id}`, 'PUT', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function deleteImage(image) {
  const response = await wrapFetch(`images/${image.id}`, 'DELETE', {});
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}


/***********************************************
**************** TEST TEMPLATES ****************
***********************************************/


async function createTestTemplate(test_template) {
  const body = JSON.stringify({
    internal_id: test_template.internal_id.length === 0 ? '-1' : test_template.internal_id,
    category: test_template.category,
    classification: test_template.classification,
    assay: test_template.assay,
    components: test_template.components,
    standards: test_template.standards,
    minimum_value: test_template.minimum_value,
    minimum_unit: test_template.minimum_unit,
    published: test_template.published
  });
  const response = await wrapFetch('test_templates', 'POST', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function fetchTestTemplates() {
  return await (await wrapFetch('test_templates', 'GET')).json();
}

async function updateTestTemplate(test_template) {
  const body = JSON.stringify({
    internal_id: test_template.internal_id.length === 0 ? '-1' : test_template.internal_id,
    category: test_template.category,
    classification: test_template.classification,
    assay: test_template.assay,
    components: test_template.components,
    standards: test_template.standards,
    minimum_value: test_template.minimum_value,
    minimum_unit: test_template.minimum_unit,
    published: test_template.published
  });
  const response = await wrapFetch(`test_templates/${test_template.id}`, 'PUT', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function deleteTestTemplate(test_template) {
  const response = await wrapFetch(`test_templates/${test_template.id}`, 'DELETE', {});
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}


/*************************************
*************** PANELS ***************
*************************************/


async function createPanel(panel) {
  const body = JSON.stringify({
    internal_id: panel.internal_id.length === 0 ? '-1' : panel.internal_id,
    name: panel.name,
    standard_test_templates: panel.standard_test_templates,
    add_on_test_templates: panel.add_on_test_templates === "" ? [] : panel.add_on_test_templates,
    published: panel.published ? "true" : "false"
  });
  const response = await wrapFetch('panels', 'POST', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function fetchPanels() {
  return await (await wrapFetch('panels', 'GET')).json();
}

async function updatePanel(panel) {
  const body = JSON.stringify({
    internal_id: panel.internal_id.length === 0 ? '-1' : panel.internal_id,
    name: panel.name,
    standard_test_templates: panel.standard_test_templates,
    add_on_test_templates: panel.add_on_test_templates,
    published: panel.published
  });
  const response = await wrapFetch(`panels/${panel.id}`, 'PUT', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function deletePanel(panel) {
  const response = await wrapFetch(`panels/${panel.id}`, 'DELETE', {});
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}


/**************************************
**************** TESTS ****************
**************************************/


async function createTest(test) {
  const body = JSON.stringify({
    sample_id: test.sample_id,
    test_template_id: test.test_template_id,
    metadata: test.metadata
  });
  const response = await wrapFetch('tests', 'POST', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function fetchTestsForSample(sample) {
  return await (await wrapFetch(`samples/${sample.id}/tests`, 'GET')).json();
}

async function updateTest(test) {
  const body = JSON.stringify({
    sample_id: test.sample_id,
    test_template_id: test.test_template_id,
    metadata: test.metadata
  });
  const response = await wrapFetch(`tests/${test.id}`, 'PUT', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function deleteTest(test) {
  const response = await wrapFetch(`tests/${test.id}`, 'DELETE', {});
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}


/**************************************
**************** CODES ****************
**************************************/


async function createCode(email) {
  const body = JSON.stringify({ email: email });
  const response = await wrapFetch('codes', 'POST', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
  }
}

async function fetchCode(uuid) {
  return await (await wrapFetch(`codes?uuid=${uuid}`, 'GET')).json();
}


/********************************************
**************** SUBMISSIONS ****************
********************************************/


async function createSubmission(code_uuid, samples, additional_metadata) {
  const body = JSON.stringify({
    code_uuid: code_uuid,
    metadata: {
      'samples': samples,
      ...additional_metadata
    }
  });

  const response = await wrapFetch('submissions', 'POST', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function fetchSubmission(id) {
  return await (await wrapFetch(`submissions/${id}`, 'GET')).json();
}

async function updateSubmission(id, code_uuid, samples, additional_metadata, active) {
  const body = JSON.stringify({
    code_id: code_uuid,
    metadata: {
      'samples': samples,
      ...additional_metadata
    },
    active: active
  });

  const response = await wrapFetch(`submissions/${id}`, 'PUT', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}


/************************************
************** GROUPS ***************
************************************/


async function createGroup(group) {
  const body = JSON.stringify({
    name: group.name
  });
  const response = await wrapFetch('groups', 'POST', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function fetchGroups() {
  return await (await wrapFetch('groups', 'GET')).json();
}

async function updateGroup(group) {
  const body = JSON.stringify({
    name: group.name
  });
  const response = await wrapFetch(`groups/${group.id}`, 'PUT', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function deleteGroup(group) {
  const response = await wrapFetch(`groups/${group.id}`, 'DELETE', {});
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}


/************************************
************* MATRICES **************
************************************/


async function createMatrix(matrix) {
  const body = JSON.stringify({
    group_id: matrix.group_id,
    name: matrix.name
  });
  const response = await wrapFetch('matrices', 'POST', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function fetchMatrices() {
  return await (await wrapFetch('matrices', 'GET')).json();
}

async function updateMatrix(matrix) {
  const body = JSON.stringify({
    group_id: matrix.group_id,
    name: matrix.name
  });
  const response = await wrapFetch(`matrices/${matrix.id}`, 'PUT', body);
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function deleteMatrix(matrix) {
  const response = await wrapFetch(`matrices/${matrix.id}`, 'DELETE', {});
  if (response.status === 200) {
    return await (response).json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}


/************************************
*************** RULES ***************
************************************/


async function createRule(rule) {
  const body = JSON.stringify({
    group_id: rule.group_id,
    matrix_id: rule.matrix_id,
    test_template_ids: rule.test_template_ids,
    customer_ids: rule.customer_ids,
    additional_report_notes: rule.additional_report_notes,
    report_description: rule.report_description
  });
  const response = await wrapFetch('rules', 'POST', body);
  if (response.status === 200) {
    return await response.json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function fetchRules() {
  return await (await wrapFetch('rules', 'GET')).json();
}

async function updateRule(rule) {
  const body = JSON.stringify({
    group_id: rule.group_id,
    matrix_id: rule.matrix_id,
    test_template_ids: rule.test_template_ids,
    customer_ids: rule.customer_ids,
    additional_report_notes: rule.additional_report_notes,
    report_description: rule.report_description
  });
  const response = await wrapFetch(`rules/${rule.id}`, 'PUT', body);
  if (response.status === 200) {
    return await response.json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}

async function deleteRule(rule) {
  const response = await wrapFetch(`rules/${rule.id}`, 'DELETE', {});
  if (response.status === 200) {
    return await response.json();
  } else {
    console.log('response: ', response);
    alert("Some error occurred. Check the console to see further details.");
  }
}


/*****************************************
************* MISCELLANEOUS **************
*****************************************/


async function htmlToPDF(htmlContent, workOrderId, sampleId, receivedAt, issuedAt) {
  const body = JSON.stringify({
    html_content: htmlContent,
    work_order_id: workOrderId, 
    sample_id: sampleId, 
    received_at: receivedAt, 
    issued_at: issuedAt
  });
  const response = await wrapFetch('html-to-pdf', 'POST', body);
  return response;
}

async function fetchInvoice(customer_id, start_date, end_date) {
  const queryParams = new URLSearchParams({
    customer_id,
    start_date,
    end_date
  }).toString();

  return await (await wrapFetch(`invoices?${queryParams}`, 'GET')).json();
}

export { 
    wrapFetch, 
    createAddress, fetchAddresses, fetchAddress, updateAddress, deleteAddress,
    createContact, fetchContacts, fetchContact, updateContact, deleteContact, 
    createBillingAccount, fetchBillingAccounts, fetchBillingAccount, updateBillingAccount, deleteBillingAccount, 
    createCustomer, fetchCustomers, fetchCustomer, updateCustomer, deleteCustomer, 
    createWorkOrder, fetchWorkOrders, fetchWorkOrdersByUuid, fetchWorkOrder, fetchLatestWorkOrder, updateWorkOrder, deleteWorkOrder, 
    createSample, fetchSamples, fetchSamplesByUuid, fetchSample, fetchLatestSample, updateSample, deleteSample, createImage, 
    fetchImages, fetchImagesForSample, updateImage, deleteImage,
    createTestTemplate, fetchTestTemplates, updateTestTemplate, deleteTestTemplate,
    createPanel, fetchPanels, updatePanel, deletePanel,
    createTest, fetchTestsForSample, updateTest, deleteTest,
    createCode, fetchCode, 
    createSubmission, fetchSubmission, updateSubmission,
    createGroup, fetchGroups, updateGroup, deleteGroup,
    createMatrix, fetchMatrices, updateMatrix, deleteMatrix,
    createRule, fetchRules, updateRule, deleteRule,
    htmlToPDF, fetchInvoice
};
