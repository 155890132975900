import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

function cleanModels(models) {
  let clean_models = models.map(model => {
    return {
      id: model.id,
      internal_id: model.internal_id,
      name: model.name,
      standard_test_templates: model.standard_test_templates,
      add_on_test_templates: model.add_on_test_templates,
      published: Boolean(model.published)
    }
  });
  clean_models = clean_models.sort((a, b) => b.internal_id.replace(/\D/g,'') - a.internal_id.replace(/\D/g,''));
  return clean_models;
}

const columns = [{
  dataField: 'internal_id',
  text: 'ID',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'name',
  text: 'Name',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'standard_test_templates',
  text: 'Standard Tests',
  sort: false,
  classes: 'table-cell',
  formatter: cell => cell.length
}, {
  dataField: 'add_on_test_templates',
  text: 'Add-on Tests',
  sort: false,
  classes: 'table-cell',
  formatter: cell => cell.length
}, {
  dataField: 'published',
  text: 'Is Published',
  sort: false,
  classes: 'table-cell',
  formatter: (cell) => cell ? '✓' : 'x',
}];

const PanelsTable = (props) => {
  const panels = cleanModels(props.state.models[props.state.tab]);
    
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (!props.state.is_loading) {
        props.setSelectedModel(e, props.state.tab, rowIndex, row.id);
      }
    }
  };
  
  return (
    <BootstrapTable keyField='id' data={panels} columns={columns} rowEvents={rowEvents} bootstrap4 />
  )
}

export default PanelsTable;